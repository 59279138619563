/* LikedPosts.css */

.liked-posts-container {
    font-family: 'Arial', sans-serif;
    width: 100%;
    margin: 2rem auto;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.post-container-item {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.post-container-item h3 {
    color: #555;
    font-size: 20px;
    margin-bottom: 10px;
}

.post-container-item p {
    color: #666;
    font-size: 16px;
    line-height: 1.6;
}
