/* General container */

@import '../../../colors.css';

.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
    color: var(--font-color);
    padding: 2rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 50px auto;
    border-radius: 8px;
  }
  
  /* Title styling */
  .login-form-container
  h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Input styling */
  .login-form-container
  input {
    width: 100%;
    padding: 10px 15px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border 0.3s;
  }
  
  .login-form-container
  :focus {
    border-color: #007BFF; /* Matching the button color */
  }

  .login-form {
    width: 300px;
  }
  
  /* Button styling */
  .login-container
  button {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .login-form-container
  button:hover {
    background-color: var(--tertiary-color);
  }
  

  /* Container holding the registration option text and button */
.register-option {
  display: flex;
  justify-content: center;  /* Horizontally center contents */
  align-items: center;      /* Vertically center contents */
  margin-top: 20px;         /* Provide some spacing from the login form */
  font-size: 16px;
}

/* Styling for the registration button */
.register-option button {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
  margin-left: 10px;      /* Provide some spacing from the text */
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-option button:hover {
  background-color: var(--tertiary-color);
}