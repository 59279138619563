.logo-container img {
    width: 80px;
    height: 80px;
}

.logo-text {
    background-color: orange;
    border-radius: 20%;
    padding: 5px;
    color: white;
}