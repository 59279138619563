.settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
    padding: 2rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 50px auto;
    border-radius: 8px;
}
.settings h2 {
    color: #333;
    margin-bottom: 20px;
}

.theme-toggle {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.theme-toggle input {
    cursor: pointer;
}
