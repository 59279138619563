/* CreatePost.css */
@import '../../../colors.css';

.post-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
  padding: 2rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 320px;
  margin: 50px auto;
  border-radius: 8px;
}

.post-form-container h2 {
  color: #333;
  margin-bottom: 15px;
}

.posts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 175px;
  width: 100%;
}

.posts-container textarea {
  width: 90%; /* Adjusted width */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #626262;
  background-color: var(--secondary-color);
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s;
  resize: none; /* Disables resizing the textarea */
  height: 100px; /* Initial height of the textarea */
}

.posts-container textarea:focus {
  border-color: var(--primary-color);
}

.post-form-container button {
  display: inline-block;
  width: 100%; /* Or any desired width */
  padding: 10px 10px;
  margin: auto; /* This centers the button */
  margin-top: 10px; /* Adjust as needed */
  margin-bottom: 10px; /* Adjust as needed */
  background-color: var(--secondary-color);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}


.post-form-container button:hover {
  background-color: var(--tertiary-color);
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 4px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.custom-file-upload:hover {
  background-color: var(--tertiary-color);
}

.file-upload {
  display: flex;
  flex-direction: row;
  align-items: center;  
  gap: 10px;
  
}
