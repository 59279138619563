@import '../../colors.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color);
  padding: 10px 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333333;
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  z-index: 1000;
}

  /* Styles specific to the Logo component when it's inside the header */
  .logo-container {
    flex: 1; /* This ensures the logo gets centered */
    display: flex;
    justify-content: left;
    align-items: center;
  }
  
  .logo-container img {
    max-width: 200px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1)); /* Optional shadow */
  }
  
  .header-notification-icon {
    margin-right: 20px;
    width: 20px;
    height: 20px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);

  }
