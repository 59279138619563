@import '../../../colors.css';

.home-container {
    font-family: 'Arial', sans-serif;
    background-color: var(--background-color);
    width: 100%;
    margin: 2rem auto;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-container h1 {
    color: #333;
    margin-bottom: 20px;
}


.post-container-item:last-child {
    margin-bottom: 0;
}



/* buttons */

.sorting-buttons {
    display: inline-flex;
    background-color: var(--tertiary-color);  /* Color of the larger oval */
    border-radius: 25px;  /* Make it oval */
    padding: 5px 15px;  /* Padding around buttons */
    margin-bottom: 20px;  /* Space beneath the button group */
}

.sorting-buttons button {
    background-color: transparent;
    border: none;
    color: var(--font-color);
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color;  /* Smooth transition */
}

.sorting-buttons button.active {
    background-color: var(--secondary-color);  /* Color of the smaller oval */
    border-radius: 15px;  /* Make it oval */
}

.sorting-buttons button:hover {
    background-color: var(--secondary-color);
    border-radius: 15px;
}

.sorting-buttons button:focus {
    outline: none;
}

