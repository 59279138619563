@import '../../colors.css';

.post-container-item {
    display: flex;
    flex-direction: column;
    background-color: var(--tertiary-color);
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    margin-bottom: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.post-container-item:last-child {
    margin-bottom: 0;
}


/*like heart button*/

img.like-button {
    background-color: none;
    border: none;
    height: 40px;
    width: 50px;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    float: right; /* Align to the right */
    filter: brightness(0) invert(1) saturate(0);
    transition: transform 0.2s, background-color 0.3s; /* Added transition */
}

img.like-button:hover {
    transform: scale(0.95); /* Shrinks slightly */
}

img.like-button:active, 
img.like-button:focus {
  outline: none;
  /* Optionally, add a different style for better accessibility */
  box-shadow: none; /* or any other style */
}


.post-image {
    height: 325px;
    width: 325px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 10px;
}

.post-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

/*post banner*/
.post-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.post-banner button {
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color;  /* Smooth transition */
}

/* options button */
.post-options-icon {
 /*filter image to white*/
    filter: brightness(0) invert(1) saturate(0);


}

.post-options-icon:hover {
    cursor: pointer;
}

.dropdown-container {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    background-color: var(--secondary-color);
    top: 100%;
    left: auto;
    right: -15px;
    z-index: 1000;
    display: none; /* Hidden by default */
}

.dropdown-container .dropdown-menu.active {
    display: block; /* Shown when active */
}




.view-comments-btn {
    background-color: transparent;
    border: none;
    color: var(--font-color);
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color;  /* Smooth transition */
}


.likes {
    display: flex;
    flex-direction: row;
    float: right;
    align-items: center;
}

.post-username:hover {
    cursor: pointer;

}