/* Edit Profile Component CSS */
@import '../../../../colors.css';

.edit-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 600px;
    margin: auto;
    background-color: var(--tertiary-color);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.edit-profile-header {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.edit-profile-form {
    width: 100%;
    padding: 10px 20px;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
}

.edit-profile-form input {
    width: 200px;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.edit-profile-actions {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.edit-profile-button {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.edit-profile-button:hover {
    background-color: var(--secondary-color);
}

.edit-profile-form button {
    background-color: var(--primary-color);
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 20px;
    cursor: pointer;
}

.edit-profile-form button:hover {
    background-color: var(--tertiary-color);
}
