/* General container */

@import '../../../colors.css';

.registration-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
    padding: 2rem;
    color: var(--font-color);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 50px auto;
    border-radius: 8px;
}

/* Title styling */
.registration-form-container h2 {
    color: #333;
    margin-bottom: 20px;
}

/* Form styling */
.registration-form {
    width: 300px;
}

/* Input styling */
.registration-form-container input {
    width: 100%;
    padding: 10px 15px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border 0.3s;
}

.registration-form-container input:focus {
    border-color: var(--secondary-color); /* Matching the button color */
}

/* Button styling */
.registration-container button {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.registration-form-container button:hover {
    background-color: var(--tertiary-color);
}
