@import '../../../colors.css';

.dashboard-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
    padding: 2rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 50px auto;
    border-radius: 8px;
  }
  
  .dashboard-form-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  
  .dashboard-item {
    width: 50%;
    padding: 10px 15px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    background-color: var(--tertiary-color);
    color: var(--font-color);
    transition: background-color 0.3s;
  }
  
  .dashboard-item:hover {
    background-color: var(--secondary-color); /* Light grey background on hover */
  }
  