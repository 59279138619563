/* User Profile Component CSS */
@import '../../../colors.css';

.user-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 600px;
    margin: auto;
    background-color: var(--tertiary-color);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .user-profile-header {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .user-profile-info {
    width: 100%;
    padding: 10px 20px;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  
  .user-profile-info p {
    margin: 10px 0;
    font-size: 16px;
    color: #666;
  }
  
  .user-profile-actions {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .user-profile-button {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .user-profile-button:hover {
    background-color: var(--secondary-color);
  }
  
  