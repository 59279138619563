@import '../../../colors.css';


.comment-input-container {
  display: flex;
  position: relative;
  align-items: center;
  width: 75%;
  gap: 10px;
  margin-bottom: 20px;
}

.comment-input[type="text"] {
    flex-grow: 1;
    padding: 10px 15px;
    border: 2px solid #d1d1d1;
    border-radius: 20px;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s;
}

.comment-input[type="text"]:focus {
    outline: none;
    border-color: #ff6a00; /* Highlight color when focused */
}

.submit-button[type="submit"] {
    display: flex;
    padding: 5px 10px;
    background-color: #ff6a00;
    color: white;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button[type="submit"]:hover {
    background-color: #ff6a00;
}

.comment-options {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    align-items: center;
}

@media (max-width: 400px) {
  .comment-options {
      right: -50px; /* Adjust this value as needed */
  }
}

.lock-button img {
  display: flex;
  align-items: center;
  text-align: center;
  width: 18px;
  height: 18px;
  filter: brightness(0) invert(1) saturate(0);

}

/*lock button*/
.lock-button {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: none;
  height: 25px;
  width: 30px;
  margin: 0 auto;
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  
}

/* slider */
.switch {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 34px;
    margin-bottom: 10px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    outline: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-color);
    transition: .4s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 45%;
    left: 3px;
    bottom: 4px;
    background-color:var(--secondary-color);
    transition: .1s;
  }
  
  input:checked + .slider:before {
    left: 50%;
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 30px;
  }
  
  .slider-text {
    color: white;
    font-size: 14px;
    z-index: 1;
  }
  
  input:checked + .slider .slider-text:first-child,
  .slider:not(:checked) + .slider .slider-text:last-child {
    color: white;
  }