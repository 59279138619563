@import './colors.css';

body, html {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}


*, *::before, *::after {
  box-sizing: border-box;
  outline: none;
}

a {
  color: black; /* Choose your preferred color */
  text-decoration: none; /* Removes the underline; adjust as needed */
  /* Other styles as needed */
}

a:active {
  color: black; /* Color for active links */
}

a:visited {
  color: black; /* Color for visited links */
}


button, 
a,
img, 
input[type="button"], 
input[type="submit"], 
input[type="reset"] {
    -webkit-tap-highlight-color: transparent; /* For iOS */
    -webkit-touch-callout: none; /* Prevents iOS from displaying an action sheet when you hold a link */
    /* Add other styles as necessary */
    touch-action: manipulation; /* Potentially reduces additional tap feedback */
}


/* Light Theme */
:root {
  --bg-color: #ffffff;
  --text-color: #000000;
}

/* Dark Theme */
[data-theme='dark'] {
  --bg-color: #000000;
  --text-color: #ffffff;
}

/* Light theme styles */
header {
  background-color: lightgray;
}

/* Dark theme styles */
[data-theme='dark'] header {
  background-color: darkgray;
}



.login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content {
  padding-top: 60px;
  margin: 0 auto;
}