@import '../../colors.css';

.menu-container {
  display: flex;
  justify-content: space-between;
  background-color: var(--background-color);
  align-items: center;
  padding: 10px 0px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  margin: 0 auto;
}

.menu-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px;
  transition: background-color 0.3s; /* For hover effect */
  position: relative; /* Needed for positioning the dot */
}

.menu-item:hover {
  cursor: pointer;
}

.icon {
  width: 32px;
  height: 30px;
  margin-bottom: 5px;
  filter: brightness(0) invert(1) saturate(0);
}

/* Style for the active menu item */
.menu-item.active::after {
  content: '';
  position: absolute;
  bottom: -5px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 8px; /* Size of the dot */
  height: 8px; /* Size of the dot */
  background-color: rgb(150, 150, 150);
  border-radius: 50%;
  opacity: 1; /* Dot is visible for the active item */
  transition: opacity 0.3s; /* Smooth transition for the dot */
}
